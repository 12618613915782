@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

h1 {
  font-size: 48px;
}

.header-div {
  text-align: center;
}

.top-menu-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f5faff;
  border-bottom: #ecf1f5 solid 1px;
}

.top-menu-links-container {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  text-align: center;
}

.top-menu-link {
  color: #505050;
  font-size: 14px;
  text-decoration: none;
  padding: 5px;
  margin-left: 15px;
  margin-right: 15px;
}

.body-container {
  display: flex;
  justify-content: center;
  align-items: start;
}

.body-inner-container {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logo {
  margin-right: 20px;
}

.box {
  text-align: left;
  padding: 5px;
  background-color: #EFEFEF;
  border-bottom: #BFBFBF solid 1px;
}

.product-box {
  background-color: #F5F5F5;
  padding: 40px;
  border-radius: 14px;
  box-shadow: 4px 4px 24px #DADADA;
  margin: 20px;
  text-align: center;
  width: 300px;
}

.product-box:hover {
  background-color: #f5faff;
}

.product-boxes-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.product-boxes-inner-container {
  width: 80%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.product-box-link {
  color: #121212;
  text-decoration: none;
  font-size: 16px;
}

.infobox-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.infobox {
  padding: 40px;
  margin-left: 20px;
  margin-right: 20px;
  width: 20%;
}

.inline-link {
  text-decoration: none;
  color: #0000D0;
}

.inline-link:hover {
  text-decoration: underline;
}

.footer-container {
  margin-top: auto;
  display: flex;
  justify-content: center;
  background-color: #ebebeb;
  padding-top: 20px;
  padding-bottom: 20px;
}

.footer-inner-container {
  width: 80%;
  color: #282c34;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.footer-item-container {
  margin-right: 20px;
}

.footer-link {
  text-decoration: none;
  color: #282c34;
  padding: 5px;
}

.footer-link:hover {
  text-decoration: underline;
}
